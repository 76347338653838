import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from 'helpers/api'
import { toast } from "react-toastify";
import { t } from 'i18next';

export const fetchAvailableReaders = createAsyncThunk('checkIn/availableReaders', async (params, { dispatch }) => {
    try {
        const response = await api.get("web/availableReaders");
        if (!response.data.Result) {
            toast.error(t(`api.errors.${response?.data?.Code}`));
        }

        return response?.data;
    } catch (error) {
        toast.error(t('api.errors.error'));
        throw error;
    }
})

export const fetchUserCheckIns = createAsyncThunk('checkIn/list', async (params, { dispatch }) => {
    try {
        const response = await api.get("web/mycheckins", params);
        if (!response.data.Result) {
            toast.error(t(`api.errors.${response?.data?.Code}`));
        }

        return response?.data;
    } catch (error) {
        toast.error(t('api.errors.error'));
        throw error;
    }
})

export const doCheckIn = createAsyncThunk('checkIn', async (params, { dispatch }) => {

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    try {
        const response = await api.post("web/check_in", params, config);
        if (!response.data.Result) {
            toast.error(t(`api.errors.${response?.data?.Code}`));
        }

        return {
            params,
            response: response.data
        };
        
    } catch (error) {
        toast.error(t('api.errors.error'));
        throw error;
    }
})

export const confirmCheckIn = createAsyncThunk('checkIn/verify', async (params, { dispatch }) => {

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    try {
        const response = await api.post("web/verify", params, config);
        if (!response.data.Result) {
            toast.error(t(`api.errors.${response?.data?.Code}`));
        } else {
            dispatch(fetchUserCheckIns());
        }

        return response?.data;
    } catch (error) {
        toast.error(t('api.errors.error'));
        throw error;
    }
})

const initialState = {
    selected: false,
    nfc: null,
    qr: null,
    list: [],
};

export const checkInSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        clearNfcAndQr: (state) => {
            state.nfc = null;
            state.qr = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchUserCheckIns.fulfilled, (state, action) => {
            state.list = action.payload.Data
        })
        builder.addCase(doCheckIn.fulfilled, (state, action) => {
            if (action.payload.params.qr) {
                state.qr = action.payload.params.qr
                state.nfc = null;
            } else {
                state.nfc = action.payload.params.nfc
                state.qr = null;
            }
        })
    }
});

export const { clearNfcAndQr } = checkInSlice.actions;

export default checkInSlice.reducer;
