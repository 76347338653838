export const appSettings = {
  name: 'ADNorte - Tags',
  description: "",
  layout: "vertical",
  logo: {
    url: "/adnorte-logo.png",
    alt: "adnorte-logo",
    href: "/",
    width: 300,
    height: 100,
  },
  logoMobile: {
    url: "",
    alt: "",
    href: "/",
    width: 512,
    height: 512,
  },
  defaultLanguage: "pt",
  author: {
    name: "Purple Profile",
    websiteUrl: "https://purpleprofile.pt",
    address: "",
  },
  countdown: {
    size: '50',
    strokeWidth: '4',
  },
  toast: {
    position: 'bottom-right',
    className: 'bg-white',
    success: {
      className: 'bg-white',
      duration: 5000,
    },
    error: {
      className: 'bg-white',
      duration: 5000
    },
  }
}