// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import storage from "redux-persist/lib/storage"
import { persistStore, persistReducer } from "redux-persist"
import createFilter from 'redux-persist-transform-filter';

// ** Reducers
import app from './app';
import auth from './auth';
import user from './user';
import checkin from './check-in';

// const persistCheckIns = createFilter("checkin", ["list"]);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'checkin'],
  // transforms: [persistCheckIns]
}

const rootReducer = combineReducers({
  app,
  auth,
  user,
  checkin
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
    // devTools: false
})

const persistor = persistStore(store);

export { store, persistor }