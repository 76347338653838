import React from 'react';
import { ReactComponent as PoweredByLokk } from 'assets/logos/PoweredByLokk.svg'
import { useSelector } from 'react-redux';

const Footer = () => {

  const { logged } = useSelector(state => state.auth);

  if (!logged) {
    return null;
  }

  return (
    <div className="bg-white shadow-lg h-full w-full flex justify-center items-center gap-1 font-normal text-sm">
      <p>Powered by</p> <a className='hover:text-[#004077] transition-all duration-150' href="https://www.adnorte.pt/" target="_blank">Águas do Norte</a>
      {/* <p>Powered by</p> <a className='hover:text-[#542870] transition-all duration-150' href="https://purpleprofile.pt">Purple Profile</a> */}
    </div>
    // <div className="h-full w-full flex justify-center items-center bg-white">
    //   <PoweredByLokk width="100" className="fill-black" />
    // </div>
  )
}

export default Footer;