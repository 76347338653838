export const menuItems = [
    {
        title: "Home",
        icon: "heroicons-outline:home",
        isHide: false,
        roles: ['ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_USER'],
        link: "/home",
    },
    {
        title: "History",
        icon: "carbon:location-company",
        link: "/history",
        roles: ['ROLE_MANAGER', 'ROLE_CLIENT'],
        isHide: false,
    },
    // {
    //   title: "Companies",
    //   icon: "carbon:location-company",
    //   link: "companies",
    //   roles: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
    //   isHide: false,
    // }
];