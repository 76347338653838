import CustomIcon from 'components/ui/CustomIcon';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { logout } from 'store/auth';
import { ReactComponent as PoweredByLokk } from 'assets/logos/PoweredByLokk.svg'

const MobileFooter = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const links = [
    {
      path: "/home",
      title: "Home",
      icon: "fa-solid:home",
    },
    {
      path: "/history",
      title: "Check-ins",
      icon: "material-symbols:history",
    }
  ]

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  }

  const { logged } = useSelector(state => state.auth);

  if (!logged) {
    return null;
  }

  return (
    <div className="bg-white shadow-inner h-full w-full flex justify-center items-center gap-1 font-normal text-sm">
      <p>Powered by</p> <a className='hover:text-[#004077] transition-all duration-150' href="https://www.adnorte.pt/" target="_blank">Águas do Norte</a>
      {/* <p>Powered by</p> <a className='hover:text-[#542870] transition-all duration-150' href="https://purpleprofile.pt">Purple Profile</a> */}
    </div>
  )
}

export default MobileFooter;