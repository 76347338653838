import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { appSettings } from 'config/app';

import { menuItems } from 'config/sidebarItems';

import cn from 'classnames';
import { updateSidebar } from 'store/app';
import CustomIcon from 'components/ui/CustomIcon';
import Icon from 'components/ui/Icon';
import SimpleBar from 'simplebar-react';

const Sidebar = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const locationName = location.pathname.replace("/", "")

  const { sidebar } = useSelector(state => state.app);
  const { roles: userRoles } = useSelector(state => state.auth.user);

  const closeSidebar = () => {
    dispatch(updateSidebar(false))
  }

  const handleNavigate = (link) => {
    navigate(link);
    closeSidebar();
  }

  return (
    <div className={cn(
      'absolute w-[50vw] lg:w-[30vw] top-0 h-screen bg-white shadow-lg flex flex-col justify-start z-40',
      {
        'start-[-50vw] lg:start-[-30vw] transition-all duration-150': !sidebar,
        'start-0 transition-all duration-150': sidebar,
      }
    )}>
      <div className="flex justify-between items-center py-5 px-5 border-b border-slate-100">
        <div className="flex items-center gap-x-2">
          <img
            className="w-[80px] lg:w-[150px]"
            src={appSettings.logo.url}
            alt={appSettings.logo.alt}
          />
          {/* <CustomIcon icon="heroicons:shopping-bag" width="25"/>
          <span className="font-medium">
            {t('cart.label')}
          </span> */}
        </div>
        <CustomIcon icon="heroicons:x-mark" width="25" onClick={() => closeSidebar()} className="cursor-pointer" />
      </div>
      <div className='p-3 h-full flex flex-col justify-between'>
        <SimpleBar>
          <ul>
            {menuItems.map((item, index) => (
              <li key={index} className={`my-2 py-2 px-4 rounded-md cursor-pointer 
                  ${location.pathname === item.link ? 'bg-primary text-white' : 'hover:bg-slate-100'}
                `}
                onClick={() => handleNavigate(item.link)}
              >{item?.title}</li>
            ))}
          </ul>
        </SimpleBar>
        <div className='text-end'>
          <p>Logout</p>
        </div>
      </div>
    </div>
  )
}

export default Sidebar;