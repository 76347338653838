import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { appSettings } from 'config/app';
import { updateSidebar } from 'store/app';
import { logout } from 'store/auth';

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import CustomIcon from 'components/ui/CustomIcon';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const TopBar = () => {

  const navigate = useNavigate();
  const { logged } = useSelector(state => state.auth);

  return (
    <div className='bg-white shadow-lg h-full w-full px-4 py-2 flex items-center [&>*]:items-center [&>*]:inline-flex'>
      <div className='inline-flex w-1/2 justify-start'>
      </div>
      <div className='inline-flex shrink-0'>
        <img
          // className="w-[80px] lg:w-[150px]"
          className="w-[100px]"
          src={appSettings.logo.url}
          alt={appSettings.logo.alt}
          onClick={() => navigate('/')}
        />
      </div>
      <div className='w-1/2 justify-end'>
        {logged &&
          <Dropdown />
        }
      </div>
    </div>
  )
}

const Dropdown = () => {

  const { name } = useSelector(state => state.auth.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  }

  return (
    <Menu as="div" className="relative inline-block text-left z-[20]">
      <div>
        <MenuButton className="inline-flex w-full items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          <CustomIcon icon="ion:menu" />
        </MenuButton>
      </div>

      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className='px-4 py-3 border-b-2 border-solid border-slate-100'>
            <p className='text-md'>{name && name}</p>
            {/* <p className='text-sm'>{t(`roles.${role}`)}</p> */}
            {/* <p className='text-sm'>{name}</p> */}
          </div>
          <div className="py-1">
            <MenuItem>
              {({ focus }) => (
                <a
                  // href="/"
                  onClick={() => navigate('/')}
                  className={classNames(
                    focus ? 'cursor-pointer flex items-center gap-2 bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center gap-2 px-4 py-2 text-sm'
                  )}
                >
                  <CustomIcon icon='fa-solid:home' />
                  {t('general.home')}
                </a>
              )}
            </MenuItem>
            <MenuItem>
              {({ focus }) => (
                <a
                  // href="/history"
                  onClick={() => navigate('/history')}
                  className={classNames(
                    focus ? 'cursor-pointer flex items-center gap-2 bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex gap-2 items-center px-4 py-2 text-sm'
                  )}
                >
                  <CustomIcon icon='material-symbols:history' />
                  {t('general.history')}
                </a>
              )}
            </MenuItem>
            <MenuItem>
              {({ focus }) => (
                <a
                  // href="/history"
                  onClick={() => navigate('/profile')}
                  className={classNames(
                    focus ? 'cursor-pointer flex items-center gap-2 bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex gap-2 items-center px-4 py-2 text-sm'
                  )}
                >
                  <CustomIcon icon='bi:person-fill' />
                  {t('nav.profile')}
                </a>
              )}
            </MenuItem>
          </div>
          <div className='py-2 border-t-2 border-solid border-slate-100'>
            <MenuItem>
              {({ focus }) => (
                <button
                  type="submit"
                  onClick={() => handleLogout()}
                  className={classNames(
                    focus ? 'flex gap-2 items-center cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex gap-2 items-center w-full px-4 py-2 text-left text-sm'
                  )}
                >
                  <CustomIcon icon="material-symbols:logout" width="15" className="cursor-pointer" />
                  Logout
                </button>
              )}
            </MenuItem>
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  )
}

export default TopBar;