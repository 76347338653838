import Router from "router";
import "../src/assets/main.css"
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearNfcAndQr } from "store/check-in";

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearNfcAndQr());
  }, [])

  return (
    <Router />
  );
}

export default App;
