import React, { useEffect, useState } from 'react';
import TopBar from 'components/navigation/TopBar';
import MobileFooter from 'components/navigation/MobileFooter';
import Footer from 'components/navigation/Footer';
import Loading from 'components/modals/Loading';
import Sidebar from 'components/navigation/Sidebar';
import { appSettings } from 'config/app';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Vertical = ({ children }) => {

  return (
    <div className="flex flex-col items-center w-screen h-[100dvh] lg:h-screen lg:full bg-slate-100 font-extralight relative overflow-hidden lg:overflow-visible">
      <Loading />
      <ToastContainer
        position={appSettings.toast.position}
      />
      <Sidebar />
      <div className="w-full h-[9dvh] lg:h-[8vh]">
        <TopBar />
      </div>
      <div className="flex h-[83dvh] md:min-h-[87vh] w-[90%] [&>*]:h-full overflow-y-scroll lg:overflow-visible py-5 scrollbar-hide justify-center">
        {children}
      </div>
      <div className="h-[5vh] hidden md:flex w-full">
        <Footer />
      </div>
      <div className="w-full h-[8dvh] md:hidden">
        <MobileFooter />
      </div>
    </div>
  )
}

export default Vertical;