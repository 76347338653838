import React from "react";
import { Icon } from "@iconify/react";

const CustomIcon = ({ icon, className, width, rotate, hFlip, vFlip, onClick }) => {
  return (
    <Icon
      width={width}
      rotate={rotate}
      hFlip={hFlip}
      icon={icon}
      className={className}
      vFlip={vFlip}
      onClick={onClick ? onClick : null}
    />
  );
};

export default CustomIcon;
